import Landing from "./Containers/Landing";
import "./App.css";
import { useEffect } from "react";



function App() {
  useEffect(() => {
    // // Obtener contador del backend
    // fetch("http://181.215.197.2:4000/visits")
    //   .then((res) => res.json())
    //   .then((data) => visits = data.count);

    // Incrementar contador en la base de datos
    fetch("http://181.215.197.2:4000/visits", { method: "POST" })
      .then((res) => res.json());
  }, []);

  return <Landing />;
}

export default App;
