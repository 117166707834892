import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#373435",
    },
    secondary: {
      main: "#F2AD00",
    },
    background: {
      default: "#d2d3d5",
    },
    neutral: {
      light: grey[50],
      medium: grey[200],
      normal: grey[700],
      main: grey[900],
    },
  },
  typography: {
    fontFamily: "Open Sans",
  },
});

export default theme;