import { AppBar, Avatar, Toolbar, Typography } from "@mui/material";
import logo from "../../Images/logo-unicaragena.svg";

const Header = () => {
  return (
    <AppBar position="relative" sx={styles.appBar}>
      <Toolbar>
        <img src={logo} alt="Kitty Katty!" className={styles.appLogo} />
      </Toolbar>
    </AppBar>
  );
};

const styles = {
  appBar: {
    bgcolor: "secondary.main",
  },
  appLogo: {
    maxWidth: 160,
    display: { xs: "none", md: "flex" },
    m: 2,
  },
};

export default Header;
