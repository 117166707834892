import { CssBaseline } from "@mui/material";
import theme from "./Config/theme";
import { ThemeProvider } from "styled-components";
import Landing from "./Containers/Landing";
import "./App.css";

function App() {
  return <Landing />;
}

export default App;
