import { CssBaseline, ThemeProvider } from "@mui/material";
import Header from "../../Components/Header";
import theme from "../../Config/theme";
import Simulator from "../../Components/Simulator";

const Landing = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Simulator/>
    </ThemeProvider>
  );
};

export default Landing;
