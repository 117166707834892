import {
  Alert,
  Box,
  Button,
  CardMedia,
  Collapse,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Icfes1 from "../../Images/icfes-1.png";
import Icfes2 from "../../Images/icfes-2.png";
import Icfes3 from "../../Images/icfes-3.png";
import Icfes4 from "../../Images/icfes-4.png";

const Simulator = () => {
  const [modelo, setModelo] = useState(0);
  const [leyenda, setLeyenda] = useState("");
  const [error, setError] = useState("");
  const [resultado, setResultado] = useState("");
  const [open, setOpen] = useState("");

  const notasRgx = new RegExp("^[0-9]+([.][0-9]+)?$");
  const cienTocuatrocientosRgx = new RegExp("\\b([1-3][0-9][0-9]|400)\\b");
  const ceroQuinientosRgx = new RegExp(
    "\\b([0-9]|[1-9][0-9]|[1-4][0-9][0-9]|500)\\b"
  );

  useEffect(() => {
    switch (modelo) {
      case 1:
        setLeyenda("Ingrese el valor del resultado en la escala de 100 a 400");
        break;
      case 2:
        setLeyenda(
          "Ingrese el valor de las pruebas separados por coma (,), ejemplo: 56.23, 48.15, 42.26, 64.23, 42.26"
        );
        break;
      case 3:
        setLeyenda(
          "Ingrese puntaje recalificado por el icfes (escala de 0 a 500)"
        );
        setOpen(true);
        break;
      case 4:
        setLeyenda(
          "Ingrese puntaje publicado por el icfes (escala de 0 a 500)"
        );
        break;

      default:
        setLeyenda("");
        break;
    }
  }, [modelo]);

  const handleChange = (event) => {
    setOpen(false);
    setModelo(event.target.value);
    limpiar();
    document.getElementById("input-values").value = "";
  };

  const handleError = () => {
    limpiar();
  };

  const handleClick = (event) => {
    let inputValue = document.getElementById("input-values").value;
    console.log(inputValue);
    switch (modelo) {
      case 1:
        if (!cienTocuatrocientosRgx.test(inputValue)) {
          setError("Por favor ingrese numero entre 100 y 400");
          return;
        }

        setResultado(((parseFloat(inputValue)-100) / 3).toFixed(2));
        break;
      case 2:
        let count = 0;
        let total = 0;
        inputValue.split(",").forEach((element) => {
          if (!notasRgx.test(element)) {
            setError(
              "Por favor ingrese el valor de las pruebas separados por coma (,)"
            );
            return;
          }
          total += parseFloat(element);
          count++;
        });

        console.log(total, count);

        setResultado((total / count).toFixed(2));
        break;

      case 3:
      case 4:
        if (!ceroQuinientosRgx.test(inputValue)) {
          setError("Por favor ingrese numero entre 0 y 500");
          return;
        }

        setResultado((parseFloat(inputValue) / 5).toFixed(2));
        break;
      default:
    }
  };

  const limpiar = () => {
    setError("");
    setResultado("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  function Copyright() {
    return (
      <Typography variant="body2" color="white">
        {"Copyright © "}
        <Link color="inherit" href="https://unicartagena.edu.co/">
          Universidad de Cartagena
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  return (
    <>
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Simulador de conversión de pruebas ICFES
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="select-modelo-label">
                Periodo de realización de prueba
              </InputLabel>
              <Select
                labelId="select-modelo-label"
                id="select-modelo"
                value={modelo}
                onChange={handleChange}
                label="Periodo de realización de prueba"
              >
                <MenuItem value={1}>Antes del año 2000</MenuItem>
                <MenuItem value={2}>
                  Entre año 2000 y segundo periodo 2011
                </MenuItem>
                <MenuItem value={3}>
                  Entre primer periodo de 2012 y primer periodo 2014
                </MenuItem>
                <MenuItem value={4}>
                  Del segundo periodo de 2014 en adelante
                </MenuItem>
              </Select>

              <TextField
                id="input-values"
                label={leyenda}
                variant="outlined"
                fullWidth
                onChange={handleError}
                sx={{ mt: 2 }}
              />

              <Collapse in={error}>
                <Alert variant="filled" severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              </Collapse>

              <Stack sx={{ pt: 4 }} direction="row" justifyContent="center">
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={handleClick}
                >
                  Simular resultado
                </Button>
              </Stack>
            </FormControl>
            <Collapse in={resultado}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Simulación de resultado: {resultado}
              </Typography>
            </Collapse>
          </Container>
        </Box>

        <Box
          sx={{
            bgcolor: "light",
            pt: 8,
            pb: 6,
          }}
        >
          <Container
            sx={{ py: 8, bgcolor: "background.primary" }}
            maxWidth="xl"
          >
            {/* End hero unit */}
            <Typography
              variant="h5"
              sx={{ mb: 5 }}
              align="justify"
              gutterBottom
            >
              Los puntajes totales, se convertirán a escala de 1 a 100, para dar
              aplicación al{" "}
              <b>Acuerdo 31 del 14 de diciembre del Consejo Superior</b> sobre
              el puntaje mínimo de <b>40/100 para programas presenciales</b> y
              al <b>Acuerdo N° 19 del 14 de diciembre de 2018</b> con relación
              al puntaje mínimo{" "}
              <b>30/100 para programas de modalidad a distancia</b>.
            </Typography>
            <Table border={1} sx={{ bgcolor: "white" }}>
              <TableHead sx={{ bgcolor: "primary.main" }}>
                <TableRow>
                  <TableCell>
                    <Typography
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      gutterBottom
                    >
                      Periodo de realización de la prueba
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      gutterBottom
                    >
                      Escala de resultado
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      gutterBottom
                    >
                      Conversión a escala de 1 a 100
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Del segundo periodo de 2014 en adelante
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Cero (0) a quinientos (500) puntos
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Se calcula dividiendo el puntaje total entre 5
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Entre el primer periodo de 2012 y el primer periodo de
                      2014.
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Cero (0) a quinientos (500) puntos. En este caso se debe
                      solicitar recalificación realizada por el ICFES.
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Se mantiene el puntaje global obtenido de acuerdo con la
                      recalificación realizada por el ICFES y se divide entre 5
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Entre el año 2000 y el segundo periodo de 2011
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Cero (0) a cien (100) en cada una de las pruebas
                      evaluadas.
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Se promedian los puntajes.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Pruebas realizadas antes del año 2000.
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Cien (100) a cuatrocientos (400) puntos.
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      Se calcula teniendo en cuenta la fórmula (puntaje - 100) /
                      3.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Container>
        </Box>
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
            backgroundColor: "primary.main",
          }}
        >
          <Container maxWidth="sm">
            <Copyright />
          </Container>
        </Box>
        <Dialog
          onClose={handleClose}
          open={open}
          scroll="body"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle align="justify" variant="h4">
            Para acceder al puntaje recalificado por el ICFES presentados entre
            primer periodo de 2012 y primer periodo de 2014
          </DialogTitle>
          <DialogContent dividers="true" sx={{ m: 2 }}>
            <Typography variant="h5" gutterBottom>
              1. Accede al link para{" "}
              <Link
                href="https://icfesinteractivo.gov.co/resultados/res_est/sniee_log_per.jsp"
                target="blank"
              >
                consultar resultados ICFES
              </Link>{" "}
              , diligencia tu número de documento o tu número de registro,
              selecciona el año de presentación y periodo, diligencia el captcha y dar click en "Entrar".
            </Typography>
            <CardMedia
              component="img"
              image={Icfes1}
              alt="Pantalla de consulta de ICFES"
              sx={{ objectFit: "contain", mb: 5 }}
            />

            <Typography variant="h5" gutterBottom>
              2. Te mostrará la pantalla con el número de registro y el botón
              para ver el detalle de tu prueba, dar click en el botón.
            </Typography>
            <CardMedia
              component="img"
              image={Icfes2}
              alt="Pantalla resultado"
              sx={{ objectFit: "contain", mb: 5 }}
            />
            <Typography variant="h5" gutterBottom>
              3. Te mostrará un cuadro de diálogo, dar click en "Ver
              resultados".
            </Typography>
            <CardMedia
              component="img"
              image={Icfes3}
              alt="Pantalla dialogo recalificación"
              sx={{ objectFit: "contain", mb: 5 }}
            />
            <Typography variant="h5" gutterBottom>
              4. Te redireccionará a la pantalla con la recalificación, se debe
              tomar el puntaje global marcado en rojo.
            </Typography>
            <CardMedia
              component="img"
              image={Icfes4}
              alt="Pantalla dialogo recalificación"
              sx={{ objectFit: "contain", mb: 5 }}
            />
          </DialogContent>
        </Dialog>
      </main>
    </>
  );
};

export default Simulator;
